<template>
	<div class="text-center">
		<v-dialog
			v-model="jbConfigDialog"
			width="75%"
			class="col-6 ml-auto mr-auto">
			<template v-slot:activator="{ on, attrs }">
				<slot
					name="button"
					:on="on"
					:attrs="attrs"></slot>
			</template>

			<v-card>
				<v-card-title
					style="position: relative"
					:class="'headline primary'">
					<span>
						{{ selectedJokerBonusConfig.id > -1 ? "Edit" : "Create" }} Joker
						Bonus Configuration
					</span>
					<div
						style="
							position: absolute;
							right: 10px;
							top: 50%;
							transform: translate(0, -50%);
						"
						class="d-flex flex-row">
						<v-icon
							@click="exportConfig"
							style="position: relative; top: 0; right: 0"
							class="kajot-text--text"
							size="30">
							mdi-arrow-down-bold-circle
						</v-icon>

						<v-icon
							@click="importDialog = true"
							style="position: relative; top: 0; right: 0"
							class="kajot-text--text"
							size="30">
							mdi-arrow-up-bold-circle
						</v-icon>
					</div>
				</v-card-title>
				<v-card-text class="d-flex flex-wrap px-2 py-4">
					<v-col
						v-if="hasTwoCols"
						cols="12"
						md="3"
						class="mx-0 px-0 pt-0">
						<v-col
							class="pt-0"
							v-if="jokerBonusConfigList.length > 0">
							<v-select
								:items="jokerBonusConfigList"
								v-model="selectedJokerBonusConfig"
								@input="onSelectedJBConfig"
								item-value="id"
								item-text="name"
								label="Select Jackpot Config"
								:rules="[rules.required]"
								return-object></v-select>
						</v-col>

						<v-col
							class="pt-0"
							v-if="jokerBonusListByConfigId.length > 1">
							Joker Bonus List using the same config:
							<div
								v-for="jokerBonus of jokerBonusListByConfigId"
								v-bind:key="jokerBonus.id"
								class="red--text">
								id : {{ jokerBonus.id }}
							</div>
						</v-col>
						<v-col
							class="pt-0"
							v-if="
								jokerBonusContribType == undefined &&
								jokerBonusConfigList.length == 0
							">
							<!-- {{ selectedJokerBonusConfig }}
							{{ selectContribType }} -->
							<v-select
								:items="contribTypeDropdown"
								v-model="selectContribType"
								item-text="name"
								item-value="name"
								label="Select Contribution Type"
								:rules="[rules.required]"></v-select>
						</v-col>
					</v-col>
					<v-col>
						<JokerBonusConfigForm
							:value="selectedJBConfigCopy"
							@input="JBConfigUpdate"
							:contribTypeOfGroup="contribType"
							:disableForm="
								!allowEditOfPreMadeConfig && preMadeConfig
							"></JokerBonusConfigForm>
					</v-col>
				</v-card-text>

				<v-divider />

				<v-card-actions col="12">
					<v-btn
						text
						@click="jbConfigDialog = false">
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click="submit"
						:disabled="!enableSubmit">
						Submit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<ImportDialog
			ref="dialog"
			:validator="jsonValidator"
			:errorTimeout="1500"
			title="Bonus Config Import"
			:width="500"
			submitButtonText="Import"
			:format="['application/json']"
			dropFileType="JSON"
			parser="jsonParser"
			@submit="onImport"
			no-hint
			:importDialog.sync="importDialog"></ImportDialog>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import JokerBonusConfigForm from "../jokerBonusConfig/JokerBonusConfigForm.vue";
import ImportDialog from "../../shared/ImportDialog.vue";
import { rules as defaultRules } from "../../../plugins/rules.ts";
import { CONTRIB_TYPES } from "../../../constants/contribTypes";
import { saveAsFile } from "../../../constants/helpers";

export default {
	components: {
		JokerBonusConfigForm,
		ImportDialog,
	},

	props: {
		color: {
			type: String,
			default: "primary",
		},
		allowEditOfPreMadeConfig: {
			type: Boolean,
			default: false,
		},
		editJokerBonusConfigData: {
			type: Object,
			default: function () {
				return {};
			},
		},
		jokerBonusConfigList: {
			type: Array,
			default: function () {
				return [];
			},
		},
		jokerBonusListByConfigId: {
			type: Array,
			default: function () {
				return [];
			},
		},
		jokerBonusContribType: {
			type: String,
		},
		cleaning: {
			type: Boolean,
		},
	},

	data() {
		return {
			importDialog: false,
			selectedJBConfigCopy: {},

			selectedJokerBonusConfig: {},
			innerJokerBonusConfigList: [],
			dataLoaded: false,
			enableSubmit: false,

			configId: undefined,
			configName: "",
			groupContribType: CONTRIB_TYPES.PERCENT,
			configForm: {},
			getForm: false,
			/** ^^^^ */
			valid: true,
			selectContribType: undefined,
			rules: {
				...defaultRules,
			},

			jbConfigDialog: false,
			contribTypeDropdown: [CONTRIB_TYPES.PERCENT, CONTRIB_TYPES.BET],
		};
	},

	computed: {
		hasTwoCols() {
			return (
				this.jokerBonusConfigList.length > 0 ||
				this.jokerBonusListByConfigId.length > 1 ||
				(this.jokerBonusContribType == undefined &&
					this.jokerBonusConfigList.length == 0)
			);
		},
		preMadeConfig() {
			return this.selectedJokerBonusConfig?.id > -1;
		},
		contribType() {
			if (this.jokerBonusContribType !== undefined) {
				return this.jokerBonusContribType;
			}

			return this.selectContribType;
		},
	},

	methods: {
		saveAsFile,
		...mapActions("notification", ["setMessage"]),
		jsonValidator(data) {
			const importType = data.data.contrib_ratio
				? CONTRIB_TYPES.PERCENT
				: CONTRIB_TYPES.BET;
			if (
				this.jokerBonusContribType != undefined &&
				this.jokerBonusContribType !== importType
			) {
				return "Contribution type mismatch.";
			}
			return true;
		},
		exportConfig() {
			const { created, updated, deleted, id, name, ...tmpExport } =
				this.selectedJBConfigCopy;
			this.saveAsFile(tmpExport, this.selectedJBConfigCopy.name);
		},
		async onImport(jbData) {
			/* TODO: CHECK IF CONTRIB TYPE CAN BE CHANGED
			IF IT CANT, CHECK TO THROW ERROR */
			if (jbData.data.contrib_ratio) {
				this.selectContribType = CONTRIB_TYPES.PERCENT;
			} else {
				this.selectContribType = CONTRIB_TYPES.BET;
			}
			await this.$nextTick();
			this.selectedJBConfigCopy = jbData.data;
		},
		onSelectedJBConfig(e) {
			this.selectedJBConfigCopy = { ...e };
		},

		JBConfigUpdate([val, valid]) {
			this.enableSubmit = valid;
		},

		async submit() {
			this.$emit("submit", this.selectedJBConfigCopy);
			this.jbConfigDialog = false;
		},
	},

	watch: {
		async editJokerBonusConfigData(newVal) {
			await this.$nextTick();
			this.selectedJokerBonusConfig = newVal;
			this.onSelectedJBConfig(newVal);
		},
	},
};
</script>
