var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "col-6 ml-auto mr-auto",
          attrs: { width: "75%" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [_vm._t("button", null, { on: on, attrs: attrs })]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.jbConfigDialog,
            callback: function ($$v) {
              _vm.jbConfigDialog = $$v
            },
            expression: "jbConfigDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: "headline primary",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedJokerBonusConfig.id > -1
                            ? "Edit"
                            : "Create"
                        ) +
                        " Joker Bonus Configuration "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: {
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translate(0, -50%)",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "kajot-text--text",
                          staticStyle: {
                            position: "relative",
                            top: "0",
                            right: "0",
                          },
                          attrs: { size: "30" },
                          on: { click: _vm.exportConfig },
                        },
                        [_vm._v(" mdi-arrow-down-bold-circle ")]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "kajot-text--text",
                          staticStyle: {
                            position: "relative",
                            top: "0",
                            right: "0",
                          },
                          attrs: { size: "30" },
                          on: {
                            click: function ($event) {
                              _vm.importDialog = true
                            },
                          },
                        },
                        [_vm._v(" mdi-arrow-up-bold-circle ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-wrap px-2 py-4" },
                [
                  _vm.hasTwoCols
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mx-0 px-0 pt-0",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _vm.jokerBonusConfigList.length > 0
                            ? _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.jokerBonusConfigList,
                                      "item-value": "id",
                                      "item-text": "name",
                                      label: "Select Jackpot Config",
                                      rules: [_vm.rules.required],
                                      "return-object": "",
                                    },
                                    on: { input: _vm.onSelectedJBConfig },
                                    model: {
                                      value: _vm.selectedJokerBonusConfig,
                                      callback: function ($$v) {
                                        _vm.selectedJokerBonusConfig = $$v
                                      },
                                      expression: "selectedJokerBonusConfig",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.jokerBonusListByConfigId.length > 1
                            ? _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _vm._v(
                                    " Joker Bonus List using the same config: "
                                  ),
                                  _vm._l(
                                    _vm.jokerBonusListByConfigId,
                                    function (jokerBonus) {
                                      return _c(
                                        "div",
                                        {
                                          key: jokerBonus.id,
                                          staticClass: "red--text",
                                        },
                                        [
                                          _vm._v(
                                            " id : " +
                                              _vm._s(jokerBonus.id) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.jokerBonusContribType == undefined &&
                          _vm.jokerBonusConfigList.length == 0
                            ? _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.contribTypeDropdown,
                                      "item-text": "name",
                                      "item-value": "name",
                                      label: "Select Contribution Type",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.selectContribType,
                                      callback: function ($$v) {
                                        _vm.selectContribType = $$v
                                      },
                                      expression: "selectContribType",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    [
                      _c("JokerBonusConfigForm", {
                        attrs: {
                          value: _vm.selectedJBConfigCopy,
                          contribTypeOfGroup: _vm.contribType,
                          disableForm:
                            !_vm.allowEditOfPreMadeConfig && _vm.preMadeConfig,
                        },
                        on: { input: _vm.JBConfigUpdate },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { attrs: { col: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.jbConfigDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: !_vm.enableSubmit,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        ref: "dialog",
        attrs: {
          validator: _vm.jsonValidator,
          errorTimeout: 1500,
          title: "Bonus Config Import",
          width: 500,
          submitButtonText: "Import",
          format: ["application/json"],
          dropFileType: "JSON",
          parser: "jsonParser",
          "no-hint": "",
          importDialog: _vm.importDialog,
        },
        on: {
          submit: _vm.onImport,
          "update:importDialog": function ($event) {
            _vm.importDialog = $event
          },
          "update:import-dialog": function ($event) {
            _vm.importDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }