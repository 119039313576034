<template>
	<div>
		<v-sheet
			:color="color"
			:width="'auto'"
			class="text-start v-card--material__heading mb-n6 d-flex align-center justify-space-between"
			:class="`${classAdd} pl-${paddingLeft} pr-${paddingRight} pt-${paddingTop} pb-${paddingBottom}`"
			elevation="6"
			name="heading"
			@click="$emit('click')">
			<slot name="prepend"></slot>

			<div
				v-if="loading && loadingTitle"
				:class="`display-2 font-weight-light ${text_color}--text`">
				{{ loadingTitle }}
			</div>
			<div
				v-else
				:class="`display-2 font-weight-light ${text_color}--text`">
				<slot
					name="title"
					:title="title">
					{{ title }}
				</slot>
			</div>
			<slot name="append"></slot>
			<slot></slot>
		</v-sheet>
	</div>
</template>

<script>
export default {
	name: "CardHeading",

	props: {
		avatar: {
			type: String,
			default: "",
		},
		classAdd: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "secondary",
		},
		text_color: {
			type: String,
			default: "kajot-text",
		},
		icon: {
			type: String,
			default: undefined,
		},
		image: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		loading: {
			type: Boolean,
			default: false,
		},
		loadingTitle: {
			type: String,
			default: "",
		},
		paddingLeft: {
			type: String,
			default: "8",
		},
		paddingRight: {
			type: String,
			default: "8",
		},
		paddingTop: {
			type: String,
			default: "3",
		},
		paddingBottom: {
			type: String,
			default: "3",
		},
	},

	computed: {
		classes() {
			return {
				"v-card--material--has-heading": this.hasHeading,
			};
		},
		hasHeading() {
			return Boolean(this.$slots.heading || this.title || this.icon);
		},
		hasAltHeading() {
			return Boolean(this.$slots.heading || (this.title && this.icon));
		},
	},
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -40px
    transition: .3s ease
    z-index: 1
</style>
